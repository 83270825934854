import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { createPublicClient, formatUnits, http, parseAbiItem } from "viem";
import { baseSepolia } from "viem/chains";
import Addresses from "../../constants";
import { useChainId } from "wagmi";
import numbro from "numbro";

function Memenomics() {
  const [totalSupply, setTotalSupply] = useState(null);
  const chainId = useChainId();

  // MEME ERC20 Token Address
  const MEME_ADDRESS = Addresses[chainId].MEMECOIN; // Replace with actual MEME token address

  const [creators, setCreators] = useState(null);
  const [traders, setTraders] = useState(null);
  const [memes, setMemes] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://us-central1-memetrade-app.cloudfunctions.net/getStatistics"
        );
        console.log(response);
        if (response.ok) {
          const data = await response.json();
          setCreators(data.creators);
          setTraders(data.traders);
          setMemes(data.memes);
        } else {
          console.error(
            "Error fetching data from cloud function:",
            response.statusText
          );
        }
      } catch (error) {
        console.error("Error fetching data from cloud function:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchTotalSupply() {
      try {
        // Initialize Viem client
        const client = createPublicClient({
          chain: baseSepolia,
          transport: http(), // You can specify a custom RPC URL if needed
        });

        // ABI item for totalSupply function
        const totalSupplyAbi = parseAbiItem(
          "function totalSupply() view returns (uint256)"
        );

        // Call totalSupply function on the MEME contract
        const supply = await client.readContract({
          address: MEME_ADDRESS,
          abi: [totalSupplyAbi],
          functionName: "totalSupply",
        });

        // Convert BigInt to a readable format (assuming 18 decimals)
        const formattedSupply = formatUnits(supply, 18);

        setTotalSupply(formattedSupply); // Format number with commas
      } catch (error) {
        console.error("Error fetching totalSupply:", error);
        setTotalSupply("Error fetching data");
      }
    }

    fetchTotalSupply();
  }, [MEME_ADDRESS]);

  return (
    <Container className="mt-5">
      <Card>
        <Card.Body>
          <Card.Title>
            <h1 style={{ fontFamily: "Lobster" }} className="display-1 p-5">
              Memenomics
            </h1>
          </Card.Title>
          <Card.Text>
            <ul>
              <li>
                <strong>Token:</strong> Memecoin ($MEME)
              </li>
              <li>
                <strong>Supported Networks:</strong> Base Sepolia
              </li>
              <li>
                <strong>Contract:</strong>{" "}
                <a
                  href={`https://sepolia.basescan.org/token/${MEME_ADDRESS}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {MEME_ADDRESS}
                </a>
              </li>
              <li>
                <strong>ETH-MEME LP:</strong>{" "}
                <a
                  href={`https://sepolia.basescan.org/address/${Addresses[chainId].ETH_MEME_LP}#tokentxns`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {Addresses[chainId].ETH_MEME_LP}
                </a>
              </li>
              <li>
                <strong>Initial Supply:</strong> 1,000,000,000 (1B tokens)
              </li>
              <li>
                <strong>Current Supply:</strong>{" "}
                {totalSupply !== null
                  ? `${numbro(totalSupply).format({
                      thousandSeparated: true,
                    })} MEME`
                  : "Loading..."}
              </li>
              <li>
                <strong>Delta:</strong>{" "}
                {totalSupply - 1_000_000_000 !== null
                  ? `${numbro(totalSupply - 1_000_000_000).format({
                      thousandSeparated: true,
                    })} MEME`
                  : "Loading..."}
              </li>
              <li>
                <strong>Meme Creators:</strong> {creators}
              </li>
              <li>
                <strong>Meme Traders:</strong> {traders}
              </li>
              <li>
                <strong>Memes Produced:</strong> {memes}
              </li>
            </ul>
            <p className="mt-3">
              The initial supply of $MEME at deployment is 1B tokens. Additional
              tokens are minted as liquidity by MemeDAO when new memecoins are
              created. Due to the inflation resulting from $MEME liquidity,
              users have an incentive to spend their $MEME to buy meme coins.
              $MEME pairs have a 0.01% LP fee, whereas ETH pairs have a 0.3%
              fee. MemeTrade Interactive makes it easy to buy and spend $MEME on
              a curated platform built on a decentralized protocol governed by
              MemeDAO. The DAO ("decentralized autonomous organization") is the
              governing body of the protocol and is responsible for allocating
              funds to the treasury, which is used to buy back and burn $MEME.
            </p>
            <h3>Expected Outcomes</h3>
            <p>
              The DAO treasury will be used to buy back and burn $MEME, reducing
              the supply and increasing the value of the remaining $MEME. $MEME
              is the currency of the Republic of Kekistan, an internet nation
              who's main export is the internet meme. All Pepes are welcome to
              play subject to our{" "}
              <a href="/terms" target="_blank" rel="noreferrer">
                Terms of Service
              </a>
              .
            </p>
            <p>
              Governance is the responsibility of MemeDAO. The more XP users
              have, the more voting power they have. Users gain XP by creating
              and selling meme coins on decentralized exchanges. XP is a ranking
              system that aims to measure your contribution to the memeconomy.
              MemeTrade Interactive is a privately held platform for creating,
              discovering, and trading meme coins built on the MemeTrade
              protocol.
            </p>
            <h3 id="disclaimer">General Disclaimer</h3>
            <p>
              $MEME offered and sold through our services are intended solely
              for utility purposes. Information found on this website and the
              MemeTrade app (together as "the platform") does not constitute
              investment advice or an inducement to purchase securities. Users
              acknowledge and accept the risks inherent in utilizing blockchain
              technologies, including but not limited to market volatility,
              regulatory changes, and technological vulnerabilities. Access to
              $MEME through the platform may be restricted in certain
              jurisdictions. Users are responsible for complying with local laws
              and regulations. This token is not a security, nor is it intended
              for speculative investment purposes. It grants no ownership rights
              or claims to profits. The token serves as a means of exchange for
              services and products within the platform, facilitating
              transactions between users and service providers for the sole
              purpose of entertainment. Ownership of the token does not grant
              any rights to participate in the governance or decision-making
              processes of the platform. Users are encouraged to perform their
              own due diligence and consult independent advisors before engaging
              with the token. Users are responsible for ensuring that their use
              of the token complies with all relevant laws and regulations. The
              platform and its services are provided 'as is' without warranties
              of any kind, express or implied. Availability and functionality
              are not guaranteed. We shall not be liable for any losses or
              damages arising from the use or inability to use the token or
              platform services. We reserve the right to modify these terms and
              conditions at any time. Changes will be effective upon posting on
              the platform.
            </p>
            <p>
              For questions or concerns regarding the token or these
              disclaimers, please contact us at{" "}
              <a href="mailto:support@memetrade.com">support@memetrade.com</a>.
            </p>
            <h3>Token Allocation</h3>
            <p>Memecoin ($MEME) allocation is as follows:</p>
            <ul>
              <li>
                <strong>35% - MemeDAO</strong>
                <br />
                $MEME token holders can decide how to use use the DAO's treasury
                funds. Whether by spending $MEME capital to purchase meme coins
                for the DAO's meme portfolio, burning $MEME, or community
                grants.
                <br />
                <br />
              </li>
              <li>
                <strong>20% - ETH Liquidity Pool</strong>
                <br />
                Liquidity pool for the ETH-MEME pair on Uniswap V3. Owned by
                MemeDAO.
                <br />
                <br />
              </li>
              <li>
                <strong>20% - MemeTrade Interactive</strong>
                <br />
                The creator of MemeDAO, operator of memetrade.com and publisher
                of the MemeTrade app.
                <br />
                <br />
              </li>
              <li>
                <strong>10% - Liquidity Mining Incentive</strong>
                <br />
                Incentive for liquidity providers to provide liquidity to the
                ETH pool.
                <br />
                <br />
              </li>
              <li>
                <strong>10% - Private Sale</strong>
                <br />
                Private sale to accredited investors to help fund development
                and launch.
                <br />
                <br />
              </li>
              <li>
                <strong>5% - Airdrop</strong>
                <br />
                Airdrop to the community based on your XP ranking in the beta.
                <br />
              </li>
            </ul>
            <h3>How to Participate</h3>
            <p>
              MemeTrade Interactive is currently in development. We are
              currently exploring options for launching $MEME on Base. Check
              back soon for more information or hop in our{" "}
              <a
                href="https://discord.gg/memetrade"
                target="_blank"
                rel="noreferrer"
              >
                Discord
              </a>{" "}
              and{" "}
              <a href="https://t.me/memetrade" target="_blank" rel="noreferrer">
                Telegram
              </a>
              !
            </p>
          </Card.Text>
          {/* Additional data will be added here later */}
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Memenomics;
