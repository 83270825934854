import React from "react";

const TermsOfUse = () => {
  return (
    <div className="container my-5">
      <div className="text-center mb-4">
        <h1 className="display-4">Terms of Use</h1>
        <p className="text-muted">Last updated: November 02, 2024</p>
      </div>

      <div className="card shadow-sm">
        <div className="card-body">
          <section className="mb-5">
            <h2 className="h5">1. Agreement to Our Legal Terms</h2>
            <p>
              We are <strong>MemeTrade Interactive LLC</strong> ("Company,"
              "we," "us," "our"). We operate the website memetrade.com and the
              MemeTrade iOS app, as well as any other related products and
              services that refer or link to these legal terms (the "Legal
              Terms").
            </p>
            <p>
              You can contact us by email at{" "}
              <a href="mailto:info@memetrade.com">info@memetrade.com</a> or by
              mail to:
              <br />
              <strong>2400 Cottage Way, Ste G2, Sacramento, CA 95325</strong>.
            </p>
            <p>
              These Legal Terms constitute a legally binding agreement made
              between you, whether personally or on behalf of an entity ("you"),
              and <strong>MemeTrade</strong>, concerning your access to and use
              of the Services. You agree that by accessing the Services, you
              have read, understood, and agreed to be bound by all of these
              Legal Terms.{" "}
              <strong>
                If you do not agree with all of these Legal Terms, you are
                expressly prohibited from using the Services and must
                discontinue use immediately.
              </strong>
            </p>
          </section>

          <section className="mb-5">
            <h2 className="h5">2. Our Services</h2>
            <p>
              The information provided when using the Services is not intended
              for distribution to or use by any person or entity in any
              jurisdiction or country where such distribution or use would
              violate any law or regulation. You are responsible for compliance
              with local laws in your own jurisdiction.
            </p>
          </section>

          <section className="mb-5">
            <h2 className="h5">3. Intellectual Property Rights</h2>
            <p>
              We are the owner or the licensee of all intellectual property
              rights in our Services, including all source code, databases,
              functionality, software, website designs, audio, video, text,
              photographs, and graphics. All intellectual property rights are
              reserved.
            </p>
            <p>
              Your use of the Services grants you no rights to our intellectual
              property, which remains the property of{" "}
              <strong>MemeTrade Interactive LLC</strong> or our licensors. You
              are granted a limited, revocable, non-exclusive, non-transferable
              license to access and use the Services for your personal,
              non-commercial use.
            </p>
          </section>

          <section className="mb-5">
            <h2 className="h5">4. User Representations</h2>
            <p>By using the Services, you represent and warrant that:</p>
            <ul>
              <li>
                You have the legal capacity and agree to comply with these Legal
                Terms;
              </li>
              <li>
                You are not a minor in the jurisdiction in which you reside;
              </li>
              <li>
                You will not access the Services through automated or non-human
                means;
              </li>
              <li>
                You will not use the Services for any illegal or unauthorized
                purpose; and
              </li>
              <li>
                Your use of the Services will not violate any applicable law or
                regulation.
              </li>
            </ul>
          </section>

          <section className="mb-5">
            <h2 className="h5">5. Prohibited Activities</h2>
            <p>
              You may not use the Services for any purpose other than for which
              we make the Services available. The following are strictly
              prohibited:
            </p>
            <ul>
              <li>
                Attempting to bypass any measures designed to prevent or
                restrict access to the Services;
              </li>
              <li>
                Interfering with, disrupting, or creating an undue burden on the
                Services;
              </li>
              <li>
                Using the Services as part of any effort to compete with us or
                otherwise use the Services for revenue-generating activities or
                commercial enterprise.
              </li>
            </ul>
          </section>

          <section class="mb-5">
            <h2 class="h5">6. User-Generated Contributions</h2>
            <p>
              The Services may allow you to create, submit, post, display,
              transmit, publish, distribute, or broadcast content and materials,
              including but not limited to text, writings, video, audio,
              photographs, graphics, comments, suggestions, or personal
              information or other material (collectively, "Contributions").
              Contributions may be viewable by other users of the Services and
              through third-party websites. When you create or make available
              any Contributions, you thereby represent and warrant that:
            </p>
            <ul>
              <li>
                Your Contributions do not contain any material that is unlawful,
                abusive, defamatory, obscene, harassing, violent, or otherwise
                objectionable.
              </li>
              <li>
                Your Contributions do not violate any applicable law,
                regulation, or rule.
              </li>
              <li>
                Your Contributions do not infringe any proprietary rights,
                including but not limited to copyright, patent, trademark, trade
                secret, moral rights, or other intellectual property rights of
                any third party.
              </li>
              <li>
                You are the creator and owner of, or have the necessary
                licenses, rights, consents, releases, and permissions to use and
                to authorize us and other users of the Services to use your
                Contributions as necessary to exercise the licenses granted by
                you under these Legal Terms.
              </li>
              <li>
                Your Contributions do not contain any offensive comments related
                to race, national origin, gender, sexual preference, or physical
                handicap. Objectionable content includes, but is not limited to,
                content that is overtly offensive, passive-aggressive, or
                otherwise inappropriate and is not in good taste.
              </li>
              <li>
                Your Contributions do not violate our Terms of Use, including
                but not limited to our policy against objectionable content and
                abusive users.
              </li>
            </ul>

            <h3 class="h6 mt-4">
              6.1 Zero Tolerance for Objectionable Content
            </h3>
            <p>
              We enforce a zero-tolerance policy for objectionable content and
              abusive behavior. You agree that any Contributions that violate
              this policy are strictly prohibited. Objectionable content
              includes, but is not limited to, content that is unlawful,
              abusive, harassing, defamatory, obscene, violent, pornographic,
              threatening, or otherwise offensive or inappropriate.
            </p>

            <h3 class="h6 mt-4">6.2 Content Moderation and Filtering</h3>
            <p>
              We employ automated and manual methods for filtering objectionable
              content, which include but are not limited to machine learning
              algorithms, keyword filters, and human moderation. These tools
              help to identify and filter out content that may violate our terms
              and is deemed objectionable. However, we cannot guarantee the
              accuracy of our filtering and moderation systems, and we reserve
              the right to review and remove any content at our discretion.
            </p>

            <h3 class="h6 mt-4">6.3 User Reporting and Blocking Mechanism</h3>
            <p>
              Our Services include mechanisms for users to report objectionable
              content and block other users who engage in abusive behavior. If
              you encounter content or behavior that you believe violates these
              terms, please use our in-app reporting tools or contact our
              support team at{" "}
              <a href="mailto:support@memetrade.com">support@memetrade.com</a>.
              Reports will be reviewed promptly, and appropriate action will be
              taken.
            </p>
            <p>
              You can also block users who you find abusive or who post
              objectionable content. Blocking a user will prevent further
              interactions with that user within the Services.
            </p>

            <h3 class="h6 mt-4">6.4 Response and Content Removal</h3>
            <p>
              We take reports of objectionable content and abusive users
              seriously. Upon receiving a report of objectionable content, we
              will review the content within 24 hours and take appropriate
              action, which may include removing the content and ejecting the
              offending user from the Services. We reserve the right, at our
              sole discretion, to suspend or terminate any user who violates
              these terms, including repeat offenders or users responsible for
              severe violations.
            </p>

            <h3 class="h6 mt-4">6.5 Acknowledgement and Agreement</h3>
            <p>
              By using our Services, you agree to comply with these terms and
              acknowledge that we have the right to remove any content that
              violates these terms without notice to you. Failure to adhere to
              these terms may result in suspension or termination of your
              account, as well as legal action, if applicable.
            </p>
          </section>

          <section className="mb-5">
            <h2 className="h5">7. Contribution License</h2>
            <p>
              By submitting any feedback or suggestions to us, you agree that we
              may use and share such feedback for any purpose without
              compensation to you. You retain full ownership of your
              Contributions but grant us a license to use them as outlined
              above.
            </p>
          </section>

          <section className="mb-5">
            <h2 className="h5">8. Services Management</h2>
            <p>
              We reserve the right, but not the obligation, to monitor the
              Services for violations of these Legal Terms; take appropriate
              legal action against anyone who violates the law or these Legal
              Terms; and manage the Services in a way designed to protect our
              rights and property.
            </p>
          </section>

          <section className="mb-5">
            <h2 className="h5">9. Modifications and Interruptions</h2>
            <p>
              We reserve the right to change, modify, or remove the contents of
              the Services at any time or for any reason at our sole discretion
              without notice. We cannot guarantee the Services will be available
              at all times, and we will not be liable for any interruptions or
              errors.
            </p>
          </section>

          <section className="mb-5">
            <h2 className="h5">10. Governing Law</h2>
            <p>
              These Legal Terms shall be governed by and defined following the
              laws of the United States (California). MemeTrade and you consent
              to the jurisdiction of the courts located in Monterey County,
              California to resolve any disputes.
            </p>
          </section>

          <section className="mb-5">
            <h2 className="h5">11. Dispute Resolution</h2>
            <p>
              To expedite resolution and control costs, you agree to first
              attempt to negotiate any dispute informally for at least 30 days
              before initiating arbitration. Any dispute arising out of these
              Terms shall be referred to the International Commercial
              Arbitration Court under the European Arbitration Chamber. The
              number of arbitrators shall be one, the seat of arbitration shall
              be Monterey County, California, and the language of proceedings
              shall be English.
            </p>
          </section>

          <section className="mb-5">
            <h2 className="h5">12. Corrections</h2>
            <p>
              We reserve the right to correct any errors or omissions on the
              Services, including pricing and availability, without prior
              notice.
            </p>
          </section>

          <section className="mb-5">
            <h2 className="h5">13. Disclaimer</h2>
            <p>
              THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE." TO THE
              FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
              EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
              NON-INFRINGEMENT.
            </p>
          </section>

          <section className="mb-5">
            <h2 className="h5">14. Limitations of Liability</h2>
            <p>
              In no event will we or our directors, employees, or agents be
              liable for any direct, indirect, incidental, special, or punitive
              damages, including lost profit, revenue, data, or other damages
              arising from your use of the Services, even if we have been
              advised of the possibility of such damages.
            </p>
          </section>

          <section className="mb-5">
            <h2 className="h5">15. Indemnification</h2>
            <p>
              You agree to indemnify, defend, and hold us harmless from any
              claim or demand, including attorneys' fees, arising out of your
              breach of these Legal Terms or your use of the Services.
            </p>
          </section>

          <section className="mb-5">
            <h2 className="h5">16. User Data</h2>
            <p>
              We will maintain certain data that you transmit to the Services
              for the purpose of managing the Services. You agree that we shall
              have no liability for any loss or corruption of such data.
            </p>
          </section>

          <section className="mb-5">
            <h2 className="h5">
              17. Electronic Communications, Transactions, and Signatures
            </h2>
            <p>
              Visiting the Services or sending emails to us constitutes
              electronic communications. By using the Services, you consent to
              receive electronic communications and agree that electronic
              communications satisfy any legal requirements that such
              communications be in writing.
            </p>
          </section>

          <section class="mb-5">
            <h2 class="h5">18. Miscellaneous</h2>
            <p>
              These Legal Terms and any policies or operating rules posted by us
              on the Services or in respect to the Services constitute the
              entire agreement and understanding between you and us. Our failure
              to exercise or enforce any right or provision of these Legal Terms
              shall not operate as a waiver of such right or provision.
            </p>
            <p>
              These Legal Terms operate to the fullest extent permissible by
              law. We may assign any or all of our rights and obligations to
              others at any time. We shall not be responsible or liable for any
              loss, damage, delay, or failure to act caused by any cause beyond
              our reasonable control.
            </p>
            <p>
              If any provision or part of a provision of these Legal Terms is
              determined to be unlawful, void, or unenforceable, that provision
              or part of the provision is deemed severable from these Legal
              Terms and does not affect the validity and enforceability of any
              remaining provisions.
            </p>
            <p>
              There is no joint venture, partnership, employment, or agency
              relationship created between you and us as a result of these Legal
              Terms or use of the Services. You agree that these Legal Terms
              will not be construed against us by virtue of having drafted them.
              You hereby waive any and all defenses you may have based on the
              electronic form of these Legal Terms and the lack of signing by
              the parties hereto to execute these Legal Terms.
            </p>
          </section>

          <section className="mb-5">
            <h2 className="h5">19. Contact Us</h2>
            <p>
              For complaints or further information, please contact us at:
              <br />
              <a href="mailto:info@memetrade.com">info@memetrade.com</a> or by
              mail to{" "}
              <strong>2400 Cottage Way, Ste G2, Sacramento, CA 95325</strong>.
            </p>
          </section>

          <footer className="text-center mt-5">
            <p>© 2024 MemeTrade Interactive LLC. All rights reserved.</p>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
