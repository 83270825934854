import { useState, useEffect } from "react";
import { Button, Modal, InputGroup, Form } from "react-bootstrap";
import { parseUnits, formatUnits } from "viem";
import {
  useAccount,
  usePublicClient,
  useWalletClient,
  useBalance,
  useChainId,
} from "wagmi";

import Quoter from "@uniswap/v3-periphery/artifacts/contracts/lens/QuoterV2.sol/QuoterV2.json";
import ISwapRouter02 from "@uniswap/swap-router-contracts/artifacts/contracts/interfaces/ISwapRouter02.sol/ISwapRouter02.json";
import Addresses from "../constants";
import numbro from "numbro";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const SwapModal = ({ show, onHide, meme }) => {
  // State variables
  const [inputToken, setInputToken] = useState("ETH"); // New state for input token
  const [inputAmount, setInputAmount] = useState("");
  const [memeAmount, setMemeAmount] = useState(undefined);
  const [error, setError] = useState("");
  const [poolFeePercent, setPoolFeePercent] = useState("");
  const [pricePerMeme, setPricePerMeme] = useState("");
  const [feeAmount, setFeeAmount] = useState("");
  const [sendToRecipient, setSendToRecipient] = useState(false);
  const [recipientAddress, setRecipientAddress] = useState("");
  const [totalValueLockedToken0, setTotalValueLockedToken0] = useState("");
  const [totalValueLockedToken1, setTotalValueLockedToken1] = useState("");
  const [pool, setPool] = useState(null);

  // Hooks from wagmi
  const { address, isConnected } = useAccount();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const chainId = useChainId();

  // Apollo Client for Uniswap subgraph
  const [client, setClient] = useState(null);

  useEffect(() => {
    if (meme?.chainId && meme?.pool) {
      const subgraphId =
        meme.chainId === 8453
          ? "GqzP4Xaehti8KSfQmv3ZctFSjnSUYZ4En5NRsiTbvZpz"
          : "4xPAdAuU9HfbQhNdGCfZYBw45Ey6KB71R3dc4qCD5XhQ";

      const APIURL = `https://gateway.thegraph.com/api/2227547dd6b388e9abc997ebfe7b5dbc/subgraphs/id/${subgraphId}`;

      const newClient = new ApolloClient({
        uri: APIURL,
        cache: new InMemoryCache(),
      });

      setClient(newClient);
    }
  }, [meme]);

  // Fetch user's ETH balance
  const { data: ethBalance } = useBalance({
    address: address,
    chainId: chainId,
  });

  const { data: memeBalance } = useBalance({
    address: address,
    token: Addresses[chainId].MEMECOIN,
    chainId: chainId,
  });

  const getQuoteFromInputAmount = async (amount) => {
    if (!amount || isNaN(Number(amount)) || Number(amount) <= 0) {
      setMemeAmount(undefined);
      setPricePerMeme("");
      setFeeAmount("");
      return;
    }

    try {
      // Check if the pool exists
      const poolAddress = meme.pool;

      if (!poolAddress) {
        alert("Pool does not exist");
        return;
      }

      // Query the pool data
      const result = await client.query({
        query: gql`
          {
            pool(
              id: "${poolAddress.toLowerCase()}"
            ) {
              token0 {
                id
                symbol
              }
              token1 {
                id
                symbol
              }
              liquidity
              feeTier
              sqrtPrice
              token0Price
              token1Price
              volumeToken0
              volumeToken1
              totalValueLockedToken0
              totalValueLockedToken1
              totalValueLockedUSD
              volumeUSD
              feesUSD
            }
          }
        `,
      });

      const pool = result.data.pool;
      setPool(pool);

      const amountInWei = parseUnits(amount, 18);
      const amountInEther = Number(amount);

      // Determine the swap path based on input token
      let path = [];
      let fees = [];
      let poolFee = 0;

      if (inputToken === "ETH") {
        // Swap path: ETH -> MEME -> Meme Coin
        path = [
          Addresses[chainId].WETH,
          Addresses[chainId].MEMECOIN,
          meme.address,
        ];
        fees = [3000, 100]; // WETH-MEME: 0.3%, MEME-MemeCoin: 0.01%
        poolFee = 0.3 + 0.01; // Sum of pool fees
      } else {
        // Swap path: MEME -> Meme Coin
        path = [Addresses[chainId].MEMECOIN, meme.address];
        fees = [100]; // MEME-MemeCoin: 0.01%
        poolFee = 0.01;
      }

      setPoolFeePercent(poolFee);

      // Calculate the fee amount
      const feeAmountValue = amountInEther * (poolFee / 100);
      setFeeAmount(feeAmountValue.toFixed(6));

      console.log(amountInWei);

      // Simulate getting quote using Quoter contract
      const quotedAmount = await publicClient?.simulateContract({
        address: Addresses[chainId].UNISWAP_V3_QUOTER, // Quoter address
        abi: Quoter.abi,
        functionName: "quoteExactInput",
        args: [encodePath(path, fees), amountInWei],
      });

      if (quotedAmount) {
        const amountOut = quotedAmount.result[0];
        const memeAmountInEther = formatUnits(amountOut, 18);
        setMemeAmount(memeAmountInEther);

        // Compute price per Meme Coin
        const memeAmountNum = Number(memeAmountInEther);
        const pricePerMemeCoin = amountInEther / memeAmountNum;
        setPricePerMeme(pricePerMemeCoin.toFixed(6));
      } else {
        setMemeAmount(undefined);
        setPricePerMeme("");
        setFeeAmount("");
      }

      if (pool) {
        const feeTier = pool.feeTier;
        const poolFee = Number(feeTier) / 1e4; // Convert fee tier to percentage
        setPoolFeePercent(poolFee);

        // Calculate the fee amount in ETH
        const fee = amount * (poolFee / 100);
        setFeeAmount(fee.toFixed(6));
      } else {
        setError("Pool does not exist");
        return;
      }

      setTotalValueLockedToken0(pool.totalValueLockedToken0);
      setTotalValueLockedToken1(pool.totalValueLockedToken1);
    } catch (error) {
      setError("Error fetching quote. Please try again later.");
      console.error("Error fetching quote:", error);
      setMemeAmount(undefined);
      setPricePerMeme("");
    }
  };

  const encodePath = (path, fees) => {
    const FEE_SIZE = 3;

    if (path.length !== fees.length + 1) {
      throw new Error("path/fee lengths do not match");
    }

    let encoded = "0x";

    for (let i = 0; i < fees.length; i++) {
      // Encode the address
      encoded += path[i]?.slice(2).toLowerCase();
      // Encode the fee
      encoded += fees[i]?.toString(16).padStart(FEE_SIZE * 2, "0");
    }
    // Encode the last address
    encoded += path[path.length - 1].slice(2).toLowerCase();

    return encoded;
  };

  const buyMeme = async () => {
    if (
      !inputAmount ||
      isNaN(Number(inputAmount)) ||
      Number(inputAmount) <= 0
    ) {
      alert("Please enter a valid amount");
      return;
    }

    try {
      const userAddress = address;
      const amountInWei = parseUnits(inputAmount, 18);

      // Determine the swap path based on input token
      let path = [];
      let value = 0n; // Used for ETH value in transaction

      if (inputToken === "ETH") {
        path = [
          Addresses[chainId].WETH,
          Addresses[chainId].MEMECOIN,
          meme.address,
        ];
        value = amountInWei;
      } else {
        path = [Addresses[chainId].MEMECOIN, meme.address];
      }

      // Prepare swap parameters
      const params = {
        path: encodePath(path, inputToken === "ETH" ? [3000, 100] : [100]),
        recipient: sendToRecipient ? recipientAddress : userAddress,
        deadline: Math.floor(Date.now() / 1000) + 60 * 20, // 20 minutes from now
        amountIn: amountInWei,
        amountOutMinimum: 0n, // Accept any amount of Meme Coin
      };

      const gas = await publicClient.estimateContractGas({
        address: Addresses[chainId].UNISWAP_V3_SWAP_ROUTER,
        abi: ISwapRouter02.abi,
        functionName: "exactInput",
        args: [params],
        value: value,
      });

      // Execute the swap on Uniswap V3
      const swapTx = await walletClient.writeContract({
        address: Addresses[chainId].UNISWAP_V3_SWAP_ROUTER,
        abi: ISwapRouter02.abi,
        functionName: "exactInput",
        args: [params],
        value: value,
        gas,
      });

      await publicClient.waitForTransactionReceipt({ hash: swapTx });

      console.log("Swap executed successfully");
      alert("Purchase successful!");

      // Reset modal and inputs
      setInputAmount("");
      setMemeAmount(undefined);
      onHide();
    } catch (error) {
      console.error("Error purchasing meme:", error);
      alert("Purchase failed. Please try again.");
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Buy {meme.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <p className="mb-3 alert alert-danger">{error}</p>}
        <p>Enter the amount of {inputToken} you want to spend.</p>
        {pool && (
          <p>
            <strong>Total Value Locked:</strong>{" "}
            {totalValueLockedToken0 &&
              numbro(totalValueLockedToken0).format({
                thousandSeparated: true,
                mantissa: 4,
              })}{" "}
            {pool.token0.symbol} /{" "}
            {totalValueLockedToken1 &&
              numbro(totalValueLockedToken1).format({
                thousandSeparated: true,
                mantissa: 4,
              })}{" "}
            {pool.token1.symbol}
          </p>
        )}

        {/* Token Selection Dropdown */}
        <Form.Group className="mb-3">
          <Form.Label>Select Input Token</Form.Label>
          <Form.Select
            value={inputToken}
            onChange={(e) => {
              setInputToken(e.target.value);
              // Reset amounts when input token changes
              setInputAmount("");
              setMemeAmount(undefined);
              setPricePerMeme("");
              setFeeAmount("");
            }}
          >
            <option value="ETH">ETH</option>
            <option value="MEME">MEME</option>
          </Form.Select>
        </Form.Group>

        <InputGroup className="mb-3">
          <Form.Control
            type="number"
            placeholder={`Enter ${inputToken} amount`}
            value={inputAmount}
            onChange={(e) => {
              setInputAmount(e.target.value);
              getQuoteFromInputAmount(e.target.value);
            }}
          />
          <InputGroup.Text>{inputToken}</InputGroup.Text>
        </InputGroup>

        {/* Display user's balance */}
        {isConnected && (
          <p>
            Your Balance:{" "}
            {inputToken === "ETH"
              ? ethBalance
                ? parseFloat(
                    formatUnits(ethBalance.value, ethBalance.decimals)
                  ).toFixed(4)
                : "Loading..."
              : memeBalance
              ? numbro(
                  parseFloat(
                    formatUnits(memeBalance.value, memeBalance.decimals)
                  ).toFixed(4)
                ).format({
                  thousandSeparated: true,
                })
              : "Loading..."}{" "}
            {inputToken}
          </p>
        )}

        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            onChange={(e) => setSendToRecipient(e.target.checked)}
          />
          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
            Send to recipient
          </label>
        </div>

        {sendToRecipient && (
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              placeholder="Recipient address"
              onChange={(e) => setRecipientAddress(e.target.value)}
            />
          </InputGroup>
        )}

        <h5>Quote</h5>
        <p>
          <strong>Amount:</strong>{" "}
          {memeAmount !== undefined
            ? `~${parseFloat(memeAmount).toFixed(6)} ${meme.symbol}`
            : `Enter ${inputToken} amount to get quote`}
        </p>
        {pricePerMeme && (
          <p>
            <strong>Price:</strong> {pricePerMeme} {inputToken}/{meme.symbol}
          </p>
        )}
        {poolFeePercent && (
          <p>
            <strong>Total Pool Fee:</strong> {poolFeePercent}%
          </p>
        )}
        {feeAmount && (
          <p>
            <strong>Fee Amount:</strong> {feeAmount} {inputToken}
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={buyMeme}>
          Buy
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SwapModal;
