import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faInstagram,
  faFacebookF,
  faDiscord,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="footer mt-5 py-5 bg-light">
      {" "}
      {/* Increased vertical padding from py-3 to py-5 */}
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} sm={3} className="text-center">
            <span className="text-muted">
              © {year} MemeTrade Interactive LLC. All rights reserved.
            </span>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} sm={6} className="text-center m-3">
            <a href="mailto:support@memetrade.com" className="text-muted mx-2">
              Support Email
            </a>{" "}
            <Link to="/privacy" className="text-muted mx-2">
              Privacy Policy
            </Link>
            <Link to="/terms" className="text-muted mx-2">
              Terms of Service
            </Link>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} sm={6} className="text-center">
            <a
              href="https://twitter.com/memetradeapp"
              className="text-muted mx-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a
              href="https://instagram.com/memetradeapp"
              className="text-muted mx-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="https://facebook.com/memetradeapp"
              className="text-muted mx-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              href="https://discord.gg/M7NByD4PE4"
              className="text-muted mx-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faDiscord} />
            </a>
            <a
              href="https://t.me/+lEQwrmQ3CsFmMzkx"
              className="text-muted mx-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTelegram} />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
