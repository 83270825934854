import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import SwapModal from "../../components/SwapModal";
import "./index.css";
import { Button } from "react-bootstrap";

const firebaseConfig = {
  apiKey: "AIzaSyBVuRYTJrMQUD8Xtza3JC9nqlbXb3Zvz6A",
  authDomain: "memetrade-app.firebaseapp.com",
  projectId: "memetrade-app",
  storageBucket: "memetrade-app.appspot.com",
  messagingSenderId: "870431794929",
  appId: "1:870431794929:web:d168eed6dd48f8af27ab50",
  measurementId: "G-C79W7M3XLP",
};

const app = initializeApp(firebaseConfig, "app");
const db = getFirestore(app);

const MemeDetail = () => {
  const { id } = useParams();
  const [meme, setMeme] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  // Add state to store pool data
  const [poolData, setPoolData] = useState(null);

  useEffect(() => {
    const fetchMeme = async () => {
      const memeRef = doc(db, "memes", id);
      const memeSnap = await getDoc(memeRef);

      if (memeSnap.exists()) {
        setMeme(memeSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchMeme();
  }, [id]);

  useEffect(() => {
    // Fetch and display liquidity data
    console.log(meme);
  }, [meme]);

  // Add useEffect to fetch pool data when meme changes
  useEffect(() => {
    const fetchPoolData = async () => {
      if (!meme?.pool || !meme?.chainId) {
        console.log("No pool address or chain ID in meme data");
        return;
      }

      // Set up the Uniswap subgraph client
      const subgraphId =
        meme.chainId === 8453
          ? "GqzP4Xaehti8KSfQmv3ZctFSjnSUYZ4En5NRsiTbvZpz"
          : "4xPAdAuU9HfbQhNdGCfZYBw45Ey6KB71R3dc4qCD5XhQ";

      const APIURL = `https://gateway.thegraph.com/api/2227547dd6b388e9abc997ebfe7b5dbc/subgraphs/id/${subgraphId}`;

      const client = new ApolloClient({
        uri: APIURL,
        cache: new InMemoryCache(),
      });

      try {
        const result = await client.query({
          query: gql`
            {
              pool(id: "${meme.pool.toLowerCase()}") {
                id
                token0 {
                  id
                  symbol
                  decimals
                }
                token1 {
                  id
                  symbol
                  decimals
                }
                liquidity
                feeTier
                sqrtPrice
                token0Price
                token1Price
                volumeToken0
                volumeToken1
                totalValueLockedToken0
                totalValueLockedToken1
                totalValueLockedUSD
                volumeUSD
                feesUSD
              }
            }
          `,
        });
        console.log("Pool data fetched:", result.data.pool);
        setPoolData(result.data.pool);
      } catch (error) {
        console.error("Error fetching pool data:", error);
      }
    };

    if (meme) {
      fetchPoolData();
    }
  }, [meme]);

  // Function to detect if the user is on a mobile device
  const isMobileDevice = () => {
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry/i.test(
      navigator.userAgent
    );
  };

  if (!meme) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <h1>{meme.title}</h1>
      <div className="row">
        <div className="offset-md-3 col-md-6">
          <img
            src={meme.image}
            alt={meme.title}
            className="img-fluid"
            style={{
              borderRadius: "8px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              marginTop: "25px",
            }}
          />
          <blockquote className="my-3 text-center">
            {meme.description}
          </blockquote>
          <p>
            <span className="fw-bold">Price Change (24h):</span>{" "}
            <span
              style={{
                color:
                  meme["24h"] > 0 ? "green" : meme["24h"] < 0 ? "red" : "grey",
              }}
            >
              {meme["24h"].toFixed(2)}%
            </span>
          </p>
          <p>
            <span className="fw-bold">Address:</span>{" "}
            <a
              href={`https://basescan.org/token/${meme.address}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-truncate"
              title={meme.address}
            >
              {`${meme.address.slice(0, 6)}...${meme.address.slice(-4)}`}
            </a>
          </p>

          {/* Add display of liquidity data */}
          {poolData && (
            <div className="liquidity-data">
              <h3>Liquidity Data</h3>
              <p>
                <span className="fw-bold">{poolData.token0.symbol}:</span>{" "}
                <a
                  href={`https://sepolia.basescan.org/token/${poolData.token0.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {poolData.token0.id}
                </a>
              </p>
              <p>
                <span className="fw-bold">
                  Total Value Locked in {poolData.token0.symbol}:
                </span>{" "}
                {parseFloat(poolData.totalValueLockedToken0).toFixed(2)}
              </p>
              <p>
                <span className="fw-bold">{poolData.token1.symbol}:</span>{" "}
                <a
                  href={`https://sepolia.basescan.org/token/${poolData.token1.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {poolData.token1.id}
                </a>
              </p>
              <p>
                <span className="fw-bold">
                  Total Value Locked in {poolData.token1.symbol}:
                </span>{" "}
                {parseFloat(poolData.totalValueLockedToken1).toFixed(2)}
              </p>
              <p>
                <span className="fw-bold">Total Value Locked (USD):</span> $
                {parseFloat(poolData.totalValueLockedUSD).toFixed(2)}
              </p>
              <p>
                <span className="fw-bold">24h Volume (USD):</span> $
                {parseFloat(poolData.volumeUSD).toFixed(2)}
              </p>
              <p>
                <span className="fw-bold">Fees (USD):</span> $
                {parseFloat(poolData.feesUSD).toFixed(2)}
              </p>
              <p>
                <span className="fw-bold">Fee Tier:</span> {poolData.feeTier}
              </p>
            </div>
          )}

          {isMobileDevice() && (
            <div className="mb-3">
              <Link
                className="mb-3"
                to={`https://memetrade.com/mobile-wallet-protocol?id=${meme?.address}`}
              >
                View in MemeTrade app
              </Link>
            </div>
          )}

          <p>
            <Button
              variant="primary"
              size="lg"
              onClick={() => setModalVisible(true)}
            >
              Trade
            </Button>
          </p>
        </div>
      </div>

      <SwapModal
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        meme={meme}
      />
    </div>
  );
};

export default MemeDetail;
