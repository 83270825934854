import React, { useState, useEffect } from "react";
import { Button, Form, Container, Image, Spinner } from "react-bootstrap";
import {
  useAccount,
  useBalance,
  useChainId,
  usePublicClient,
  useWalletClient,
} from "wagmi";
import { formatEther, formatUnits, decodeEventLog } from "viem";
import Factory from "@memetrade-interactive/meme-dao/artifacts/contracts/Factory.sol/Factory.json";
import Addresses from "../../constants"; // Adjust the path to your contract addresses
import { db } from "../../firebaseConfig"; // Adjust the path to your Firebase config
import { collection, doc, setDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebaseConfig"; // Adjust the path to your Firebase storage

export const uploadFile = async (image) => {
  const storageRef = ref(storage, `images/${new Date().getTime()}.jpg`);

  let response = await fetch(image);
  let blob = await response.blob();

  const uploadTask = uploadBytesResumable(storageRef, blob);

  return new Promise((resolve, reject) => {
    uploadTask.on("state_changed", null, reject, async () => {
      getDownloadURL(uploadTask.snapshot.ref).then(resolve);
    });
  });
};

function Create() {
  const [image, setImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [tier] = useState("Micro-Cap");
  const [poolPrice] = useState("0.001");
  const [initialSupply] = useState("1000");

  const { address, isConnected } = useAccount();
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const chainId = useChainId();

  const { data: balance } = useBalance({
    address: address,
  });

  const [creationFee, setCreationFee] = useState(null);

  useEffect(() => {
    const fetchCreationFee = async () => {
      try {
        const fee = await publicClient.readContract({
          abi: Factory.abi,
          address: Addresses[chainId].FACTORY,
          functionName: "creationFee",
        });
        setCreationFee(fee);
      } catch (error) {
        console.error("Error fetching creation fee:", error);
      }
    };

    if (publicClient && chainId) {
      fetchCreationFee();
    }
  }, [publicClient, chainId]);

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setImage(file);
      setImagePreviewUrl(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const removeImage = () => {
    setImage(null);
    setImagePreviewUrl(null);
  };

  const uploadImage = async () => {
    try {
      const downloadURL = await uploadFile(image);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("There was an error uploading the image.");
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (symbol.trim() === "") {
      alert("Please enter a symbol.");
      return;
    }
    if (!image) {
      alert("Please select an image.");
      return;
    }
    if (!isConnected) {
      alert("Please connect your wallet.");
      return;
    }
    setLoading(true);

    try {
      const downloadURL = await uploadImage();

      if (!balance?.value) {
        alert("Unable to fetch balance. Please try again.");
        return;
      }

      if (balance.value < creationFee) {
        alert(
          `The creation fee is ${formatEther(
            creationFee
          )} ETH. You have ${formatEther(balance.value)} ETH.`
        );
        return;
      }

      // Prepare transaction parameters
      const { request } = await walletClient.prepareWriteContract({
        address: Addresses[chainId].FACTORY,
        abi: Factory.abi,
        functionName: "createMemecoin",
        args: [name, symbol, downloadURL],
        value: creationFee,
        account: address,
        chainId: chainId,
      });

      // Send transaction
      const hash = await walletClient.writeContract(request);

      const receipt = await publicClient.waitForTransactionReceipt({ hash });

      // Parse logs to find CoinCreated event
      const parsedLogs = receipt.logs
        .map((log) => {
          try {
            return decodeEventLog({
              abi: Factory.abi,
              data: log.data,
              topics: log.topics,
            });
          } catch (e) {
            return null;
          }
        })
        .filter((log) => log && log.eventName === "CoinCreated");

      const coinCreatedEvent = parsedLogs.find(
        (log) => log && log.eventName === "CoinCreated"
      );

      if (coinCreatedEvent && coinCreatedEvent.args) {
        const meme = {
          name: name,
          symbol: symbol,
          address: coinCreatedEvent.args.coin,
          pool: coinCreatedEvent.args.pool,
          description: description,
          image: downloadURL,
          createdAt: new Date(),
          creator: address,
          chainId: chainId,
          marketCap: 0,
          "24h": 0,
        };

        const memesCollectionRef = collection(db, "memes");
        const docRef = doc(memesCollectionRef);

        await setDoc(docRef, meme);

        // Clear form after submit
        setImage(null);
        setImagePreviewUrl(null);
        setName("");
        setSymbol("");
        setDescription("");
        alert("Meme created successfully.");
      } else {
        console.error("CoinCreated event not found or args are undefined.");
        alert("Error creating meme. Please try again.");
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      alert(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container style={{ maxWidth: "570px", marginTop: "20px" }}>
      <h2>Create Meme</h2>
      {loading && (
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <Spinner animation="border" role="status" />
        </div>
      )}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formImage">
          <Form.Label>Image</Form.Label>
          {imagePreviewUrl ? (
            <div>
              <Image
                src={imagePreviewUrl}
                alt="Image preview"
                thumbnail
                style={{ marginBottom: "10px" }}
              />
              <Button variant="danger" onClick={removeImage}>
                Remove Image
              </Button>
            </div>
          ) : (
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              required
            />
          )}
        </Form.Group>

        <Form.Group controlId="formName" style={{ marginTop: "10px" }}>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter meme name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            maxLength={100}
            required
          />
        </Form.Group>

        <Form.Group controlId="formSymbol" style={{ marginTop: "10px" }}>
          <Form.Label>Symbol</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter symbol (e.g., NYANCAT)"
            value={symbol}
            onChange={(e) =>
              setSymbol(e.target.value.toUpperCase().replace(/\s/g, ""))
            }
            maxLength={16}
            required
          />
          <Form.Text className="text-muted">
            Must be no longer than 8 alphanumeric characters.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="formDescription" style={{ marginTop: "10px" }}>
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Optional: add an epic caption"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            maxLength={150}
          />
        </Form.Group>

        {/* Offering Details */}
        {poolPrice && tier && (
          <div style={{ marginTop: "20px" }}>
            <h5>Offering Details</h5>
            {initialSupply && (
              <p>
                <strong>Pool Supply:</strong> {Number(initialSupply).toFixed(2)}{" "}
                {symbol ? symbol : "tokens"}
              </p>
            )}
            <p>
              <strong>Pool Price:</strong> ℳ{Number(poolPrice).toFixed(3)} per
              token
            </p>
            {tier && (
              <p>
                <strong>Tier:</strong> {tier}
              </p>
            )}
            <p>
              <strong>Creation Fee:</strong>{" "}
              {creationFee
                ? `${Number(formatUnits(creationFee, 18)).toFixed(3)} ETH`
                : "..."}
            </p>
          </div>
        )}

        {/* Balance Display */}
        {isConnected && balance && (
          <div style={{ marginTop: "20px" }}>
            <p>
              <strong>Your ETH Balance:</strong>{" "}
              {parseFloat(formatEther(balance.value)).toFixed(4)} ETH
            </p>
          </div>
        )}

        <Button
          variant="success"
          type="submit"
          disabled={loading}
          style={{ marginTop: "20px", width: "100%" }}
        >
          Create
        </Button>
      </Form>
    </Container>
  );
}

export default Create;
