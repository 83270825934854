import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  useAccount,
  useChainId,
  useDisconnect,
  usePublicClient,
  useWalletClient,
  useSwitchChain,
  useConnect,
  useBalance,
} from "wagmi";
import { parseUnits, formatUnits } from "viem";
import Quoter from "@uniswap/v3-periphery/artifacts/contracts/lens/QuoterV2.sol/QuoterV2.json";
import ISwapRouter02 from "@uniswap/swap-router-contracts/artifacts/contracts/interfaces/ISwapRouter02.sol/ISwapRouter02.json";
import UniswapV3Factory from "@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Factory.sol/IUniswapV3Factory.json";
import {
  Modal,
  Button,
  Form,
  InputGroup,
  Navbar as NavbarBS,
  Nav,
  NavDropdown,
  Container,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import Addresses from "../constants";
import { baseSepolia } from "viem/chains";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import numbro from "numbro";

function Navbar() {
  const location = useLocation();
  const [ethAmount, setEthAmount] = useState("");
  const [memeAmount, setMemeAmount] = useState(undefined);
  const { address, isConnected } = useAccount();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const chainId = useChainId();
  const { connect, connectors } = useConnect();
  const [modalVisible, setModalVisible] = useState(false);
  const { disconnect } = useDisconnect();
  const [poolFeePercent, setPoolFeePercent] = useState("");
  const [pricePerMeme, setPricePerMeme] = useState("");
  const [feeAmount, setFeeAmount] = useState("");
  const [sendToRecipient, setSendToRecipient] = useState(false);
  const [recipientAddress, setRecipientAddress] = useState("");
  const [error, setError] = useState("");
  const [totalValueLockedToken0, setTotalValueLockedToken0] = useState("");
  const [totalValueLockedToken1, setTotalValueLockedToken1] = useState("");
  const [ethUsdPrice, setEthUsdPrice] = useState(null);
  const [memeUsdPrice, setMemeUsdPrice] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  // Set up the Uniswap subgraph client
  const subgraphId =
    chainId === 8453
      ? "GqzP4Xaehti8KSfQmv3ZctFSjnSUYZ4En5NRsiTbvZpz"
      : "4xPAdAuU9HfbQhNdGCfZYBw45Ey6KB71R3dc4qCD5XhQ";

  const APIURL = `https://gateway.thegraph.com/api/2227547dd6b388e9abc997ebfe7b5dbc/subgraphs/id/${subgraphId}`;

  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  // Fetch user's ETH balance
  const { data: ethBalance } = useBalance({
    address: address,
    chainId: chainId,
  });

  // Fetch user's MEME balance
  const { data: memeBalance } = useBalance({
    address: address,
    token: Addresses[chainId].MEMECOIN,
    chainId: chainId,
  });

  // Available networks
  const networks = [{ id: baseSepolia.id, name: "Base Sepolia" }];

  // useSwitchNetwork hook
  const {
    switchChain,
    isLoading: switchChainLoading,
    pendingChainId,
    error: switchChainError,
  } = useSwitchChain();

  useEffect(() => {
    if (switchChainError) {
      alert(switchChainError.message);
    }
  }, [switchChainError]);

  // Fetch quote from ETH amount entered
  const getQuoteFromEthAmount = async (ethValue) => {
    if (!ethValue || isNaN(Number(ethValue)) || Number(ethValue) <= 0) {
      setMemeAmount(undefined);
      setPricePerMeme("");
      setFeeAmount("");
      return;
    }

    try {
      const ethAmountInWei = parseUnits(ethValue, 18);
      const ethAmountInEther = Number(ethValue);
      console.log(ethAmountInEther);

      // Check if the pool exists
      const poolAddress = await publicClient.readContract({
        address: Addresses[chainId].UNISWAP_V3_FACTORY,
        abi: UniswapV3Factory.abi,
        functionName: "getPool",
        args: [Addresses[chainId].WETH, Addresses[chainId].MEMECOIN, 3000],
      });

      console.log(`Pool exists: ${poolAddress}`);

      if (!poolAddress) {
        alert("Pool does not exist");
        return;
      }

      // Query the pool data
      const result = await client.query({
        query: gql`
        {
          pools(
            where: {
              id: "${poolAddress.toLowerCase()}"
            }
          ) {
            token0 {
              symbol
            }
            token1 {
              symbol
            }
            liquidity
            feeTier
            sqrtPrice
            token0Price
            token1Price
            volumeToken0
            volumeToken1
            totalValueLockedToken0
            totalValueLockedToken1
            totalValueLockedUSD
            volumeUSD
            feesUSD
          }
        }
        `,
      });
      console.log(`Pool data: ${JSON.stringify(result.data.pools)}`);

      const pool = result.data.pools[0];

      if (pool) {
        const feeTier = pool.feeTier;
        const poolFee = Number(feeTier) / 1e4; // Convert fee tier to percentage
        setPoolFeePercent(poolFee);

        // Calculate the fee amount in ETH
        const feeAmountEth = ethAmountInEther * (poolFee / 100);
        setFeeAmount(feeAmountEth.toFixed(6));

        // Log for debugging
        console.log(`Pool Fee Percent: ${poolFee}%`);
      } else {
        setError("Pool does not exist");
      }

      setTotalValueLockedToken0(pool.totalValueLockedToken0);
      setTotalValueLockedToken1(pool.totalValueLockedToken1);

      // Simulate getting quote using Quoter contract
      const quotedAmount = await publicClient?.simulateContract({
        address: Addresses[chainId].UNISWAP_V3_QUOTER,
        abi: Quoter.abi,
        functionName: "quoteExactInputSingle",
        args: [
          {
            tokenIn: Addresses[chainId].WETH, // tokenIn
            tokenOut: Addresses[chainId].MEMECOIN, // tokenOut
            fee: 3000, // fee (e.g., 0.3% pool fee)
            amountIn: ethAmountInWei, // amountIn
            sqrtPriceLimitX96: 0, // sqrtPriceLimitX96
          },
        ],
      });

      if (quotedAmount) {
        const amountOut = quotedAmount.result[0];
        console.log("amountOut:", formatUnits(amountOut, 18));
        const memeAmountInEther = formatUnits(amountOut, 18);
        setMemeAmount(memeAmountInEther);

        const ETH_PRICE_QUERY = gql`
          query EthPrice {
            pool(
              id: "0x46880b404cd35c165eddeff7421019f8dd25f4ad" # WETH/USDC 0.3% fee pool
            ) {
              token0Price
              token0 {
                symbol
              }
              token1Price
              token1 {
                symbol
              }
            }
          }
        `;

        const ethPriceData = await client.query({
          query: ETH_PRICE_QUERY,
        });

        console.log(`ETH Price Data: ${JSON.stringify(ethPriceData)}`);
        setEthUsdPrice(ethPriceData.data.pool.token0Price);

        // Compute price per MEME
        const ethAmountInEther = Number(ethValue);
        const memeAmountNum = Number(memeAmountInEther);
        const pricePerMeme = ethAmountInEther / memeAmountNum;
        setPricePerMeme(pricePerMeme.toFixed(6));
        setMemeUsdPrice(
          (pricePerMeme * ethPriceData.data.pool.token0Price).toFixed(6)
        );
      } else {
        setMemeAmount(undefined);
        setPricePerMeme("");
        setFeeAmount("");
      }
    } catch (error) {
      setError("Error fetching quote. Please try again later.");
      console.error("Error fetching quote:", error);
      setMemeAmount(undefined);
      setPricePerMeme("");
    }
  };

  // Function to handle the purchase
  const buyMeme = async () => {
    if (!ethAmount || isNaN(Number(ethAmount)) || Number(ethAmount) <= 0) {
      alert("Please enter a valid ETH amount");
      return;
    }

    try {
      const userAddress = address;
      const ethAmountInWei = parseUnits(ethAmount, 18);

      // Prepare swap parameters
      const params = {
        tokenIn: Addresses[chainId].WETH,
        tokenOut: Addresses[chainId].MEMECOIN,
        fee: 3000, // Pool fee tier
        recipient: sendToRecipient ? recipientAddress : userAddress,
        deadline: Math.floor(Date.now() / 1000) + 60 * 20, // 20 minutes from now
        amountIn: ethAmountInWei,
        amountOutMinimum: 0n, // Accept any amount of MEME
        sqrtPriceLimitX96: 0n, // No price limit
      };

      // Execute the swap on Uniswap V3
      const swapTx = await walletClient.writeContract({
        address: Addresses[chainId].UNISWAP_V3_SWAP_ROUTER,
        abi: ISwapRouter02.abi,
        functionName: "exactInputSingle",
        args: [params],
        value: ethAmountInWei,
      });

      await publicClient.waitForTransactionReceipt({ hash: swapTx });

      console.log("Swap executed successfully");
      alert("Purchase successful!");

      // Reset modal and inputs
      setModalVisible(false);
      setEthAmount("");
      setMemeAmount(undefined);
    } catch (error) {
      console.error("Error purchasing MEME:", error);
      alert("Purchase failed. Please try again.");
    }
  };

  // Add this useEffect to detect mobile devices
  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent =
        typeof navigator === "undefined" ? "" : navigator.userAgent;
      const mobileRegex =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      return mobileRegex.test(userAgent);
    };

    setIsMobile(checkIfMobile());
  }, []);

  const createPopover = (
    <Popover id="popover-create-button">
      <Popover.Header as="h3">Download Mobile App</Popover.Header>
      <Popover.Body>
        Please download our mobile app on{" "}
        <a href="https://testflight.apple.com/join/wknfh88W">iOS</a> to create
        memes.
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <NavbarBS bg="white" expand="lg">
        <Container fluid>
          <NavbarBS.Brand as={Link} to="/">
            <span style={{ fontFamily: "Lobster", fontSize: 34 }}>
              MemeTrade
            </span>
          </NavbarBS.Brand>
          <NavbarBS.Toggle aria-controls="navbarSupportedContent" />
          <NavbarBS.Collapse id="navbarSupportedContent">
            <Nav>
              <Nav.Link as={NavLink} to="/browse" className="m-2">
                Browse
              </Nav.Link>
            </Nav>
            <Nav className="me-auto">
              <Nav.Link as={NavLink} to="/memenomics" className="m-2">
                Memenomics
              </Nav.Link>
            </Nav>

            {isConnected && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "20px" }}>
                  <strong>ETH Balance:</strong>{" "}
                  {ethBalance
                    ? parseFloat(
                        formatUnits(ethBalance.value, ethBalance.decimals)
                      ).toFixed(4)
                    : "Loading..."}{" "}
                  ETH
                </div>
                <div style={{ marginRight: "20px" }}>
                  <strong>MEME Balance:</strong>{" "}
                  {memeBalance
                    ? numbro(
                        parseFloat(
                          formatUnits(memeBalance.value, memeBalance.decimals)
                        ).toFixed(4)
                      ).format({
                        thousandSeparated: true,
                      })
                    : "Loading..."}{" "}
                  MEME
                </div>
              </div>
            )}

            {isConnected && (
              <Nav className="m-2">
                <NavDropdown
                  title={
                    networks.find((n) => n.id === chainId)?.name ||
                    "Select Network"
                  }
                  id="networkDropdown"
                  disabled
                >
                  {networks.map((network) => (
                    <NavDropdown.Item
                      key={network.id}
                      onClick={() => switchChain?.(network.id)}
                      disabled={network.id === chainId}
                    >
                      {network.name}
                      {switchChainLoading &&
                        pendingChainId === network.id &&
                        " (switching)"}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              </Nav>
            )}

            {isConnected && (
              <Button
                variant="primary"
                size="lg"
                className="m-2"
                onClick={() => setModalVisible(true)}
              >
                Buy MEME
              </Button>
            )}

            {isConnected && isMobile && (
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={createPopover}
                rootClose
              >
                <Button variant="outline-success" size="lg" className="m-2">
                  Create
                </Button>
              </OverlayTrigger>
            )}

            {location?.pathname === "/browse" && !isConnected && (
              <Button
                variant="outline-success"
                size="lg"
                className="m-2"
                onClick={() => connect({ connector: connectors[0] })}
              >
                Connect Wallet
              </Button>
            )}

            {isConnected && (
              <Button
                variant="outline-danger"
                size="lg"
                className="m-2"
                onClick={() => disconnect()}
              >
                <i class="fa-solid fa-right-from-bracket"></i>
              </Button>
            )}
          </NavbarBS.Collapse>
        </Container>
      </NavbarBS>

      {/* Modal for buying MEME */}
      <Modal show={modalVisible} onHide={() => setModalVisible(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Buy MEME</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <p className="mb-3 alert alert-danger">{error}</p>}
          <p>Enter the amount of MEME you want to buy.</p>
          {totalValueLockedToken0 && totalValueLockedToken1 && (
            <p>
              <strong>Total Value Locked:</strong>{" "}
              {numbro(totalValueLockedToken0).format({
                thousandSeparated: true,
                mantissa: 4,
              })}{" "}
              ETH /{" "}
              {numbro(totalValueLockedToken1).format({
                thousandSeparated: true,
                mantissa: 4,
              })}{" "}
              MEME
            </p>
          )}
          <InputGroup className="mb-3">
            <Form.Control
              type="number"
              placeholder="Enter ETH amount"
              onChange={(e) => {
                setEthAmount(e.target.value);
                getQuoteFromEthAmount(e.target.value);
              }}
            />
            <InputGroup.Text>ETH</InputGroup.Text>
          </InputGroup>

          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              onChange={(e) => setSendToRecipient(e.target.checked)}
            />
            <label className="form-check-label" for="flexSwitchCheckDefault">
              Send to recipient
            </label>
          </div>

          {sendToRecipient && (
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder="Recipient address"
                onChange={(e) => setRecipientAddress(e.target.value)}
              />
            </InputGroup>
          )}

          <h5>Quote</h5>
          <p>
            <strong>Amount:</strong>{" "}
            {memeAmount !== undefined
              ? `~${numbro(parseFloat(memeAmount)).format({
                  thousandSeparated: true,
                  mantissa: 4,
                })} MEME`
              : "Enter ETH amount to get quote"}
          </p>
          {pricePerMeme && (
            <p>
              <strong>ETH Price (USD):</strong>{" "}
              {ethUsdPrice && Number(ethUsdPrice)?.toFixed(18)} ETH/USD
              <br />
              <strong>MEME Price (ETH):</strong> {pricePerMeme} ETH/MEME
              <br />
              <strong>MEME Price (USD):</strong> {memeUsdPrice} USD/MEME
            </p>
          )}
          {poolFeePercent && (
            <p>
              <strong>Pool Fee:</strong> {poolFeePercent}%
            </p>
          )}
          {feeAmount && (
            <p>
              <strong>Fee Amount:</strong> {feeAmount} ETH
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalVisible(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={buyMeme}>
            Buy
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Navbar;
