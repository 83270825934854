import mockup from "./mockup.png";
import download from "./testflight-button.png";
import image1 from "./image1.png";
import image2 from "./image2.png";
import image3 from "./image3.png";
import Ticker from "./Ticker";
import { NavLink } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";

export default function Landing() {
  return (
    <div>
      <div
        id="landing"
        className="container flex-grow-1 d-flex flex-column justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <div className="row">
          <div className="col-sm-12 col-md-6 d-flex justify-content-center align-items-center mt-5 mt-md-0">
            <div>
              <h1 className="display-1">Seize the memes of production.</h1>
              <h2 className="my-4">
                Launch, discover, and trade meme coins on Ethereum
              </h2>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://testflight.apple.com/join/wknfh88W"
                className="mt-5"
              >
                <img
                  src={download}
                  className="img-fluid mb-3"
                  width="200"
                  alt="Download on the App Store"
                />
              </a>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 d-flex justify-content-center align-items-center">
            <div className="text-center">
              <div className="row">
                <div className="col">
                  <img
                    src={mockup}
                    className="img-fluid"
                    width="400"
                    alt="Login Screen"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Ticker />

      <div
        id="product"
        className="container flex-grow-1 d-flex flex-column justify-content-center mt-5"
        style={{ minHeight: "100vh" }}
      >
        <h1
          style={{ fontFamily: "Lobster" }}
          className="display-3 text-center mb-4 mt-5"
        >
          How To Play
        </h1>
        <p className="lead text-center">
          Compete against the market to be the top meme trader.*
        </p>
        <small className="text-muted text-center">
          *For entertainment purposes only. Memes do not have any intrinsic
          financial value.
        </small>

        <div className="row my-5 position-relative">
          <div className="col">
            <div className="m-2 p-3">
              <div className="row">
                <div className="col-sm-12 col-md-6 d-flex flex-column justify-content-center">
                  <h3 className="display-5" style={{ fontFamily: "Lobster" }}>
                    Browse
                  </h3>
                  <p className="my-4 lead">
                    Swipe left to pass, swipe right to invest. Share with
                    friends and "short-sell" memes.
                  </p>
                </div>
                <div className="col-sm-12 col-md-6 text-md-end d-flex flex-column justify-content-center align-items-md-end my-4">
                  <img
                    src={image1}
                    className="img-fluid rounded border"
                    alt="Swiping"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="row my-5 position-relative">
          <div className="col">
            <div className="m-2 p-3">
              <div className="row">
                <div className="col-lg-4 col-sm-12 order-last order-md-first col-md-6 text-md-end d-flex flex-column justify-content-center align-items-md-end my-4">
                  <img
                    src={image2}
                    className="img-fluid rounded border"
                    alt="Investing"
                  />
                </div>
                <div className="offset-lg-2 col-sm-12 col-md-6 d-flex flex-column justify-content-center">
                  <h3 className="display-5" style={{ fontFamily: "Lobster" }}>
                    Invest
                  </h3>
                  <p className="lead">
                    Buy meme coins with $MEME to add the meme coin to your
                    portfolio. Discover which meme coin movement resonates with
                    you and make it a match to "invest"*.
                  </p>
                  <p className="lead my-4">
                    Each meme coin has a fair launch with a low initial supply,
                    growing based on internet virality.
                  </p>
                  <small className="text-muted">
                    *Investment theme for entertainment purposes only and not
                    based on actual financial projections. Read our{" "}
                    <NavLink to="/memenomics#disclaimer">
                      General Disclaimer
                    </NavLink>{" "}
                    to determine whether this service is suitable for you.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="row my-5 position-relative">
          <div className="col">
            <div className="m-2 p-3">
              <div className="row">
                <div className="col-sm-12 col-md-6 d-flex flex-column justify-content-center">
                  <h3 className="display-5" style={{ fontFamily: "Lobster" }}>
                    Earn
                  </h3>
                  <p className="my-4 lead">
                    Earn $MEME and XP by selling meme coins at a profit. Meme
                    creators get to purchase shares of their meme at a nominal
                    par value!
                  </p>
                </div>
                <div className="col-sm-12 col-md-6 text-md-end d-flex flex-column justify-content-center align-items-md-end my-4">
                  <img
                    src={image3}
                    className="img-fluid rounded border"
                    alt="Earning"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container flex-grow-1 d-flex flex-column justify-content-center"
        style={{ minHeight: "100vh" }}
        id="faq"
      >
        <div
          className="container flex-grow-1 d-flex flex-column justify-content-center"
          style={{ minHeight: "100vh" }}
          id="faq"
        >
          <h1
            className="text-center mb-5 display-3"
            style={{ fontFamily: "Lobster" }}
          >
            FAQ
          </h1>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>What are meme coins?</Accordion.Header>
              <Accordion.Body>
                Meme coins are cryptocurrencies created primarily as a joke or
                for entertainment purposes, often inspired by internet memes,
                viral ideas, or cultural trends. Unlike traditional
                cryptocurrencies like Bitcoin and Ethereum, which are typically
                designed to solve specific technical or financial problems, meme
                coins often have minimal to no inherent utility or serious
                development goals. Their value is usually driven by social media
                hype, community engagement, and influencer endorsements.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>How do I get $MEME?</Accordion.Header>
              <Accordion.Body>
                $MEME, the currency of the MemeTrade platform, will be available
                to purchase on{" "}
                <a href="https://uniswap.org" target="_blank" rel="noreferrer">
                  Uniswap
                </a>
                , so stay tuned by subscribing to updates on X at{" "}
                <a
                  href="https://x.com/memetradeapp"
                  target="_blank"
                  rel="noreferrer"
                >
                  @MemeTradeApp
                </a>
                ! We will also be conducting airdrops to early users of
                MemeTrade and will make $MEME available via in-app purchase.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>How do I earn XP?</Accordion.Header>
              <Accordion.Body>
                You get 50 XP every time you post! You also get XP equivalent to
                the realized gains in your meme portfolio.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>Can I cash out?</Accordion.Header>
              <Accordion.Body>
                Yes! Simply sell your meme coins when you are ready to cash out.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>What are the user ranks?</Accordion.Header>
              <Accordion.Body>
                The initial theme of the trading experience is based on a
                national memes exchange based in the Republic of Kekistan.
                <br />
                <br />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Rank</th>
                      <th scope="col">XP</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Meme God</td>
                      <td>(Top Trader)</td>
                    </tr>
                    <tr>
                      <td>Supreme Meme Overlord</td>
                      <td>1,000,000 XP</td>
                    </tr>
                    <tr>
                      <td>Meme Lord</td>
                      <td>100,000 XP</td>
                    </tr>
                    <tr>
                      <td>Meme Master</td>
                      <td>10,000 XP</td>
                    </tr>
                    <tr>
                      <td>Meme Wizard</td>
                      <td>5,000 XP</td>
                    </tr>
                    <tr>
                      <td>Meme Alchemist</td>
                      <td>3,000 XP</td>
                    </tr>
                    <tr>
                      <td>Meme Magician</td>
                      <td>2,200 XP</td>
                    </tr>
                    <tr>
                      <td>Meme Conjurer</td>
                      <td>1,500 XP</td>
                    </tr>
                    <tr>
                      <td>Cultist of Kek</td>
                      <td>1,200 XP</td>
                    </tr>
                    <tr>
                      <td>Shitpost Sheriff</td>
                      <td>900 XP</td>
                    </tr>
                    <tr>
                      <td>Shitpost Deputy</td>
                      <td>500 XP</td>
                    </tr>
                    <tr>
                      <td>Meme Factory Foreman</td>
                      <td>250 XP</td>
                    </tr>
                    <tr>
                      <td>Memesmith Journeyman</td>
                      <td>100 XP</td>
                    </tr>
                    <tr>
                      <td>Meme Sweatshop Laborer</td>
                      <td>30 XP</td>
                    </tr>
                    <tr>
                      <td>Kekistan Peasant</td>
                      <td>0 XP</td>
                    </tr>
                    <tr>
                      <td>Prisoner</td>
                      <td>&lt; 0 XP</td>
                    </tr>
                    <tr>
                      <td>Troll</td>
                      <td>&lt;= -100 XP</td>
                    </tr>
                  </tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>
                What are the supply dynamics of MEME?
              </Accordion.Header>
              <Accordion.Body>
                MEME is a payment token, and MemeTrade has mint permissions up
                to a cap of 100,000,000 to ensure an enjoyable experience for
                all. MEME has 18 decimals.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6">
              <Accordion.Header>
                What is the creator incentive?
              </Accordion.Header>
              <Accordion.Body>
                Post creators get 50 XP and greater potential for unrealized
                gains if the meme is successful. If the poster isn't the
                original copyright holder of the meme, the unrealized gains will
                be set aside for the original creator to claim. MemeTrade's
                process for copyright holder verification requires the claimant
                to meet a basic burden of proof.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7">
              <Accordion.Header>
                How do I become a verified creator?
              </Accordion.Header>
              <Accordion.Body>
                Contact{" "}
                <a href="mailto:info@memetrade.com">info@memetrade.com</a> to
                apply. As a verified creator, you can create and list new memes
                on the marketplace.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
