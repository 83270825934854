import React from "react";
import { useTicker } from "../../hooks/useTicker";
import "./Ticker.css";

const Ticker = () => {
  const { memes } = useTicker();

  if (memes.length === 0) {
    return null; // Or display a loader or placeholder
  }

  return (
    <div className="ticker">
      <div className="ticker-container">
        {memes.map((meme) => (
          <a
            key={meme.id}
            href={`/memes/${meme.id}`}
            className="ticker-item-link"
          >
            <div key={meme.id} className="ticker-item">
              <div className="d-flex align-items-center flex-column">
                <img
                  src={meme.image}
                  alt={meme.title}
                  className="ticker-image"
                />
                <span className="ticker-title">{meme.symbol}</span>
              </div>
              <div
                className={`ticker-change ${
                  meme["24h"] >= 0 ? "positive" : "negative"
                }`}
              >
                {meme["24h"].toFixed(2)}%
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Ticker;
