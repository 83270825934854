import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import {
  collection,
  getFirestore,
  query,
  where,
  orderBy,
  getDocs,
} from "firebase/firestore";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faXTwitter, faTelegram } from "@fortawesome/free-brands-svg-icons";
// import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import "./index.css";
import { useChainId } from "wagmi";
import { Alert, Col } from "react-bootstrap";

const firebaseConfig = {
  apiKey: "AIzaSyBVuRYTJrMQUD8Xtza3JC9nqlbXb3Zvz6A",
  authDomain: "memetrade-app.firebaseapp.com",
  projectId: "memetrade-app",
  storageBucket: "memetrade-app.appspot.com",
  messagingSenderId: "870431794929",
  appId: "1:870431794929:web:d168eed6dd48f8af27ab50",
  measurementId: "G-C79W7M3XLP",
};

const app = initializeApp(firebaseConfig, "app");
const db = getFirestore(app);

const Browse = () => {
  const [memes, setMemes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOption, setSortOption] = useState("popularity");
  const [filterOption, setFilterOption] = useState("all");
  const [systemStatus, setSystemStatus] = useState(
    <p>
      If you think there should be memes here, please{" "}
      <a href="mailto:support@memetrade.com">contact us</a>.
    </p>
  );
  const chainId = useChainId();

  useEffect(() => {
    setSystemStatus(
      <Col md={6} className="mx-auto">
        <Alert variant="warning">
          ⚠️ We are currently under construction. Please check back soon! ⚠️
        </Alert>
      </Col>
    );
  }, []);

  useEffect(() => {
    const fetchMemes = async () => {
      const memesRef = collection(db, "memes");
      let q = query(memesRef, where("chainId", "==", chainId || 8453));

      if (sortOption === "popularity") {
        q = query(q, orderBy("marketCap", "desc"));
      } else if (sortOption === "newest") {
        q = query(q, orderBy("createdAt", "desc"));
      } else if (sortOption === "price") {
        q = query(q, orderBy("24h", "desc"));
      }

      const querySnapshot = await getDocs(q);
      const memesData = [];
      querySnapshot.forEach((doc) => {
        memesData.push({ id: doc.id, ...doc.data() });
      });
      setMemes(memesData);
    };

    fetchMemes();
  }, [sortOption, chainId]);

  const handleSearchChange = (e) => setSearchTerm(e.target.value);
  const handleSortChange = (e) => setSortOption(e.target.value);
  const handleFilterChange = (e) => setFilterOption(e.target.value);

  const filteredMemes = memes.filter((meme) =>
    meme.name.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  return (
    <div className="">
      <header className="home-header py-5 bg-light bg-gradient">
        <h1>Discover Memecoins</h1>
        <p>Discover, trade, and enjoy the best memecoins on the blockchain.</p>
      </header>

      <div className="container">
        <div className="row mb-3">
          <div className="col-md-6">
            <input
              type="text"
              placeholder="Search memes..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="form-control my-1"
            />
          </div>

          <div className="col-md-3">
            <select
              value={sortOption}
              onChange={handleSortChange}
              className="form-select my-1"
            >
              <option value="popularity">Sort by Popularity</option>
              <option value="newest">Sort by Newest</option>
              <option value="price">Sort by Price</option>
            </select>
          </div>

          <div className="col-md-3">
            <select
              value={filterOption}
              onChange={handleFilterChange}
              className="form-select my-1"
              disabled
            >
              <option value="all">All Memes</option>
              <option value="trending">Trending</option>
              <option value="favorites">Favorites</option>
            </select>
          </div>
        </div>

        <div className="row">
          {!filteredMemes.length && (
            <>
              <p className="text-center">{systemStatus}</p>
              <p className="text-center my-5">No memes found 😓</p>
            </>
          )}
          {filteredMemes.map((meme) => (
            <a
              key={meme.id}
              className="col-md-3 col-sm-6 text-decoration-none text-dark mb-3"
              href={`/memes/${meme.id}`}
            >
              <div className="meme-card d-flex flex-column justify-content-between h-100">
                <img src={meme.image} alt={meme.title} className="meme-image" />
                <h3>{meme.name}</h3>
                <h5>${meme.symbol}</h5>
                <div className="meme-description flex-grow-1 mb-2">
                  <small className="text-muted">{meme.description}</small>
                </div>
                <p className="mt-2">
                  <span className="fw-bold">Price Δ (24h):</span>{" "}
                  <span
                    style={{
                      color:
                        meme["24h"] > 0
                          ? "green"
                          : meme["24h"] < 0
                          ? "red"
                          : "grey",
                    }}
                  >
                    {meme["24h"]?.toFixed(2)}%
                  </span>
                </p>
              </div>
              {/* <div className="social-icons">
              <a
                href="https://twitter.com/yourprofile"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faXTwitter} size="2x" />
              </a>
              <a
                href="https://t.me/yourchannel"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTelegram} size="2x" />
              </a>
              <a
                href="https://yourwebsite.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faGlobe} size="2x" />
              </a>
            </div> */}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Browse;
