const Addresses = {
  8453: {
    WETH: "0x4200000000000000000000000000000000000006",
    FACTORY: "0x49d138971B5A97F16b787c255e317e957676794a",
    MEMECOIN: "0x60ec998082D48E125C5C430781F2d9151236BDb1",
    UNISWAP_V3_FACTORY: "0x33128a8fC17869897dcE68Ed026d694621f6FDfD",
    UNISWAP_V3_QUOTER: "0x3d4e44Eb1374240CE5F1B871ab261CD16335B76a",
    UNISWAP_V3_SWAP_ROUTER: "0x2626664c2603336E57B271c5C0b26F421741e481",
  },
  84532: {
    WETH: "0x4200000000000000000000000000000000000006",
    FACTORY: "0x8d12949C44bA9dBeF0c51D31Ce411C634Ca33B5b",
    MEMECOIN: "0xC5b3C695BCA72D12960DB3b95d58f52301CB4019",
    ETH_MEME_LP: "0x32BBE3cBf87E3Dec08aA9bDb4C78A7A1078f95F2",
    UNISWAP_V3_FACTORY: "0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24",
    UNISWAP_V3_QUOTER: "0xC5290058841028F1614F3A6F0F5816cAd0df5E27",
    UNISWAP_V3_SWAP_ROUTER: "0x94cC0AaC535CCDB3C01d6787D6413C739ae12bc4",
  },
};

export default Addresses;
