import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";

function Privacy() {
  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col lg={8}>
          <h1 className="display-4 text-center mb-4">Privacy Policy</h1>
          <p className="text-muted text-center mb-5">
            Last updated November 02, 2024
          </p>

          <div className="privacy-content">
            {/* Introduction */}
            <p>
              This privacy notice for <strong>MemeTrade Interactive LLC</strong>{" "}
              (doing business as <strong>MemeTrade</strong>), describes how and
              why we might collect, store, use, and/or share ("
              <strong>process</strong>") your information when you use our
              services ("<strong>Services</strong>"), such as when you:
            </p>
            <ul>
              <li>
                Visit our website at{" "}
                <a href="https://memetrade.com">https://memetrade.com</a>, or
                any website of ours that links to this privacy notice.
              </li>
              <li>
                Download and use our mobile application (MemeTrade), or any
                other application of ours that links to this privacy notice.
              </li>
              <li>
                Engage with us in other related ways, including any sales,
                marketing, or events.
              </li>
            </ul>
            <p>
              <strong>Questions or concerns?</strong> Reading this privacy
              notice will help you understand your privacy rights and choices.
              If you do not agree with our policies and practices, please do not
              use our Services. If you still have any questions or concerns,
              please contact us at{" "}
              <a href="mailto:privacy@memetrade.com">privacy@memetrade.com</a>.
            </p>

            {/* Table of Contents */}
            <h2>Table of Contents</h2>
            <ol>
              <li>
                <a href="#section1">1. WHAT INFORMATION DO WE COLLECT?</a>
              </li>
              <li>
                <a href="#section2">2. HOW DO WE PROCESS YOUR INFORMATION?</a>
              </li>
              <li>
                <a href="#section3">
                  3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </a>
              </li>
              <li>
                <a href="#section4">
                  4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </a>
              </li>
              <li>
                <a href="#section5">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
              </li>
              <li>
                <a href="#section6">6. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
              </li>
              <li>
                <a href="#section7">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
              </li>
              <li>
                <a href="#section8">
                  8. DO WE COLLECT INFORMATION FROM MINORS?
                </a>
              </li>
              <li>
                <a href="#section9">9. WHAT ARE YOUR PRIVACY RIGHTS?</a>
              </li>
              <li>
                <a href="#section10">10. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
              </li>
              <li>
                <a href="#section11">
                  11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </a>
              </li>
              <li>
                <a href="#section12">12. DO WE MAKE UPDATES TO THIS NOTICE?</a>
              </li>
              <li>
                <a href="#section13">
                  13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </a>
              </li>
            </ol>

            {/* Section 1 */}
            <h2 id="section1">1. WHAT INFORMATION DO WE COLLECT?</h2>
            <h3>Personal Information You Disclose to Us</h3>
            <p>
              We collect personal information that you voluntarily provide to us
              when you register on the Services, express an interest in
              obtaining information about us or our products and Services, when
              you participate in activities on the Services, or otherwise when
              you contact us.
            </p>
            <p>
              The personal information that we collect depends on the context of
              your interactions with us and the Services, the choices you make,
              and the products and features you use.
            </p>
            <h4>Personal Information Provided by You</h4>
            <p>
              We collect Ethereum public addresses; usernames; and other similar
              information.
            </p>
            <h4>Sensitive Information</h4>
            <p>We do not process sensitive information.</p>
            <h4>Application Data</h4>
            <p>
              If you use our application(s), we also may collect the following
              information if you choose to provide us with access or permission:
            </p>
            <ul>
              <li>
                <strong>Mobile Device Access.</strong> We may request access or
                permission to certain features from your mobile device,
                including your mobile device's camera, and other features. If
                you wish to change our access or permissions, you may do so in
                your device's settings.
              </li>
            </ul>
            <p>
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </p>

            {/* Section 2 */}
            <h2 id="section2">2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
            <p>
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </p>
            <ul>
              <li>
                <strong>
                  To facilitate account creation and authentication and
                  otherwise manage user accounts.
                </strong>{" "}
                We may process your information so you can create and log in to
                your account.
              </li>
              <li>
                <strong>To protect our Services.</strong> We may process your
                information as part of our efforts to keep our Services safe and
                secure.
              </li>
              <li>
                <strong>
                  To enforce our terms, conditions, and policies for business
                  purposes, to comply with legal and regulatory requirements, or
                  in connection with our contract.
                </strong>
              </li>
            </ul>

            {/* Section 3 */}
            <h2 id="section3">
              3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </h2>
            <p>
              We may need to share your personal information in the following
              situations:
            </p>
            <ul>
              <li>
                <strong>Business Transfers.</strong> We may share or transfer
                your information in connection with, or during negotiations of,
                any merger, sale of company assets, financing, or acquisition of
                all or a portion of our business to another company.
              </li>
            </ul>

            {/* Section 4 */}
            <h2 id="section4">
              4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </h2>
            <p>
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information. Specific
              information about how we use such technologies and how you can
              refuse certain cookies is set out in our Cookie Notice.
            </p>

            {/* Section 5 */}
            <h2 id="section5">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>
            <p>
              Our Services offer you the ability to register and log in using
              your third-party social media account details (like your Google or
              Facebook logins). Where you choose to do this, we will receive
              certain profile information about you from your social media
              provider. The profile information we receive may vary depending on
              the social media provider concerned, but will often include your
              name, email address, friends list, and profile picture, as well as
              other information you choose to make public on such a social media
              platform.
            </p>

            {/* Section 6 */}
            <h2 id="section6">6. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
            <p>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law.
            </p>

            {/* Section 7 */}
            <h2 id="section7">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
            <p>
              We have implemented appropriate technical and organizational
              security measures designed to protect the security of any personal
              information we process. However, despite our safeguards and
              efforts to secure your information, no electronic transmission
              over the Internet or information storage technology can be
              guaranteed to be 100% secure, so we cannot promise or guarantee
              that hackers, cybercriminals, or other unauthorized third parties
              will not be able to defeat our security and improperly collect,
              access, steal, or modify your information.
            </p>

            {/* Section 8 */}
            <h2 id="section8">8. DO WE COLLECT INFORMATION FROM MINORS?</h2>
            <p>
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Services, you represent that you are
              at least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent’s use of the Services. If we
              learn that personal information from users less than 18 years of
              age has been collected, we will deactivate the account and take
              reasonable measures to promptly delete such data from our records.
            </p>

            {/* Section 9 */}
            <h2 id="section9">9. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
            <p>
              In some regions, such as the European Economic Area (EEA), United
              Kingdom (UK), and Canada, you have rights that allow you greater
              access to and control over your personal information. You may
              review, change, or terminate your account at any time.
            </p>

            {/* Section 10 */}
            <h2 id="section10">10. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
            <p>
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ("DNT") feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage no uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online.
            </p>

            {/* Section 11 */}
            <h2 id="section11">
              11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </h2>
            <p>
              Yes, if you are a resident of California, you are granted specific
              rights regarding access to your personal information.
            </p>

            {/* Section 12 */}
            <h2 id="section12">12. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
            <p>
              Yes, we will update this notice as necessary to stay compliant
              with relevant laws. The updated version will be indicated by an
              updated "Revised" date and the updated version will be effective
              as soon as it is accessible. We encourage you to review this
              privacy notice frequently to be informed of how we are protecting
              your information.
            </p>

            {/* Contact Information */}
            <h2 id="section13">
              13. How Can You Contact Us About This Notice?
            </h2>
            <p>
              If you have questions or comments about this notice, you may
              contact our Data Protection Officer (DPO) by email at{" "}
              <a href="mailto:privacy@memetrade.com">privacy@memetrade.com</a>,
              or by post to:
            </p>
            <address>
              MemeTrade Interactive LLC
              <br />
              Attn: Data Protection Officer
              <br />
              2400 Cottage Way
              <br />
              Ste G2
              <br />
              Sacramento, CA 95325
              <br />
              United States
            </address>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Privacy;
