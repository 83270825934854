import { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

export function useTicker() {
  const [memes, setMemes] = useState([]);

  useEffect(() => {
    const memesRef = collection(db, "memes");
    const q = query(
      memesRef,
      where("chainId", "==", 8453),
      orderBy("24h", "desc"),
      limit(10)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const memesData = [];
      querySnapshot.forEach((doc) => {
        console.log(doc);
        memesData.push({ id: doc.id, ...doc.data() });
      });
      setMemes(memesData);
    });

    return () => unsubscribe();
  }, []);

  return { memes };
}
