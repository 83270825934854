import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Landing from "./pages/Landing";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Footer from "./layout/Footer";
import Create from "./pages/Create";
import Browse from "./pages/Browse";
import MainLayout from "./pages/MainLayout";
import MemeDetail from "./pages/MemeDetail";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { config } from "./config";
import "bootstrap/dist/css/bootstrap.min.css";
import Memenomics from "./pages/Memenomics";

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseWebsiteConfig = {
//   apiKey: "AIzaSyCzfukZicz7VwRq5rUPUL59Uf7rnxgBsco",
//   authDomain: "memetrade-website.firebaseapp.com",
//   projectId: "memetrade-website",
//   storageBucket: "memetrade-website.appspot.com",
//   messagingSenderId: "463524262499",
//   appId: "1:463524262499:web:fae9188b341a2cdeb57e56",
//   measurementId: "G-T00111LZ2R"
// };

// Initialize Firebase
// const website = initializeApp(firebaseWebsiteConfig);
// const analytics = getAnalytics(website);

const queryClient = new QueryClient();

function App() {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <div
            className="App d-flex flex-column"
            style={{ minHeight: "100vh" }}
          >
            <Routes>
              <Route path="/" element={<MainLayout />}>
                <Route index element={<Landing />} />
                <Route path="/create" element={<Create />} />
                <Route path="/browse" element={<Browse />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/memes/:id" element={<MemeDetail />} />
                <Route path="/memenomics" element={<Memenomics />} />
              </Route>
            </Routes>
            <Footer />
          </div>
        </Router>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
