// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBVuRYTJrMQUD8Xtza3JC9nqlbXb3Zvz6A",
  authDomain: "memetrade-app.firebaseapp.com",
  projectId: "memetrade-app",
  storageBucket: "memetrade-app.appspot.com",
  messagingSenderId: "870431794929",
  appId: "1:870431794929:web:d168eed6dd48f8af27ab50",
  measurementId: "G-C79W7M3XLP",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig, "app");
// export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
